import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './features/home2/home2.module#Home2Module' },
  { path: 'about', loadChildren: './features/about/about.module#AboutModule'},
  { path: 'partnership-program', loadChildren: './features/subscribe/subscribe.module#SubscribeModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
