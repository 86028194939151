import {Component} from '@angular/core';

@Component({
  selector: 'app-general-layout',
  styleUrls: ['./general-layout.component.scss'],
  templateUrl: './general-layout.component.html'
})

export class GeneralLayoutComponent { }

