import {Component} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  emailContent = 'mailto:hb.christin@live.fr?Subject=From%20Fooncash%20Website';
}
