import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private firebaseConfig: any = {
    apiKey: 'AIzaSyCjz9mwLZLxHZ-Be7Jwk6pZDz4NGvK9B2g',
    authDomain: 'fooncash-web.firebaseapp.com',
    databaseURL: 'https://fooncash-web.firebaseio.com',
    projectId: 'fooncash-web',
    storageBucket: 'fooncash-web.appspot.com',
    messagingSenderId: '989883645717',
    appId: '1:989883645717:web:4629919705e21f173d331c',
    measurementId: 'G-8J8L7S05QR'
  };

  constructor() {
    firebase.initializeApp(this.firebaseConfig);
    firebase.analytics();
  }
}
