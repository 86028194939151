import {NgModule} from '@angular/core';
import {HeaderComponent} from './components/header/header.component';
import {RouterModule} from '@angular/router';
import {GeneralLayoutComponent} from './components/general-layout/general-layout.component';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    HeaderComponent,
    GeneralLayoutComponent
  ],
  imports: [
    RouterModule
  ],
  exports: [
    GeneralLayoutComponent
  ]
})

export class SharedModule {}
